<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="pb-5">
        <v-overlay
          :value="loading"
          absolute
        >
          <v-progress-circular
            indeterminate
            size="64"
          />
          <v-row class="py-6 font-weight-medium">
            <span>{{ loadingText }}</span>
          </v-row>
        </v-overlay>

        <v-toolbar
          v-if="product"
          :class="'product-state product-state-' + product?.state"
        >
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="yellow"
                v-bind="attrs"
                v-on="on"
              >
                <v-app-bar-nav-icon />
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-switch
                    v-model="curation.next"
                    color="primary"
                  />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ $t('views.curation.forms.labels.flow_enabled') }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $t('views.curation.forms.labels.flow_enabled_desc') }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              
              <v-list-item>
                <v-list-item-action>
                  <v-switch
                    v-model="curation.byPassPhotoProcessing"
                    color="red"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('views.curation.forms.labels.by_pass_photo_processing') }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t('views.curation.forms.labels.by_pass_photo_processing_desc') }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              
              <v-list-item v-if="curation.warning">
                <v-list-item-action>
                  <v-icon color="yellow">
                    mdi-alert
                  </v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ $t('views.curation.forms.messages.curation_warning') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t('views.curation.forms.messages.curation_warning_desc') }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-toolbar-title>Modération</v-toolbar-title>
          <v-spacer />
          <v-icon
            v-if="curation.warning"
            large
            color="yellow"
            class="mr-5"
          >
            mdi-alert
          </v-icon>
          <strong class="subheading">
            {{ product?.title }} / {{ product?.store?.name }} - <StateLabel :item="product" />
          </strong>
          <LinkProduct
            v-if="product.market_place_permalink"
            :link="product.market_place_permalink"
            small
          />
          <v-icon
            v-if="curation.warning"
            large
            color="yellow"
            class="ml-5"
          >
            mdi-alert
          </v-icon>
          <v-spacer />
          <v-btn
            icon
            @click="close"
          >
            <v-icon left>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-form
          ref="form"
          class="pa-4 pb-5"
        >
          <v-container fluid>
            <v-row class="pa-4 pb-5">
              <v-col
                cols="12"
                md="4"
                sm="6"
                xs="12"
              >
                <ProductPhotoForm
                  v-model="form.photos"
                  :error.sync="errors.photos"
                />
              </v-col>
              <v-col
                cols="12"
                md="8"
                sm="6"
                xs="12"
              >
                <ProductFeaturesForm
                  :form.sync="form"
                  :errors.sync="errors"
                  update-mode
                />
                <v-row
                  v-if="product?.rejection_reason_type"
                  class="pa-2"
                >
                  <RejectionAlert :item="product" />
                </v-row>

                <v-row v-if="isAdmin || isInternalModerator">
                  <v-col cols="8">
                    <ProductContentForm
                      :title.sync="form.title"
                      :description.sync="form.description"
                      :defects.sync="form.defects"
                      :sizing.sync="form.sizing"
                      :translations.sync="form.translations"
                      :errors.sync="errors"
                    />
                  </v-col>
                  <v-col cols="4">
                    <ProductModerationForm
                      :score.sync="form.score"
                      :favorite.sync="form.favorite"
                      :worn-photos.sync="form.wornPhotos"
                      :in-imparfaite-warehouse.sync="form.inImparfaiteWarehouse"
                    />
                  </v-col>
                </v-row>
                
                <ProductVariantsForm
                  v-model="form.variants"
                  :category-id="form.categoryId"
                  :target="form.target"
                  :errors.sync="errors.variants"
                  update-mode
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
      <v-footer fixed>
        <v-container fluid>
          <v-row>
            <v-col
              cols="12"
              md="3"
              sm="4"
              xs="12"
              class="text-left text-body-2"
            >
              id: {{ product?.id }}
            </v-col>
            <v-col
              cols="12"
              md="4"
              sm="4"
              xs="12"
              class="text-right"
            >
              <v-row justify="space-around">
                <v-btn
                  color="grey lighten-1"
                  class="white--text"
                  @click="cancel()"
                >
                  <v-icon left>
                    mdi-cancel
                  </v-icon>{{ $t('forms.buttons.cancel') }}
                </v-btn>
                <v-btn
                  :disabled="!curation.enabled"
                  color="red accent-4"
                  class="white--text"
                  @click="showRejectForm()"
                >
                  <v-icon left>
                    mdi-minus-circle
                  </v-icon>{{ $t('forms.buttons.reject') }}
                </v-btn>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="5"
              sm="4"
              xs="12"
              class="text-right"
            >
              <v-row justify="space-around">
                <v-btn
                  color="blue darken-3"
                  class="white--text"
                  @click="save()"
                >
                  <v-icon left>
                    mdi-content-save-outline
                  </v-icon>{{ $t('forms.buttons.save') }}
                </v-btn>
                <v-btn
                  v-if="curation.byPassPhotoProcessing"
                  :disabled="!curation.enabled"
                  color="green darken-4"
                  class="white--text"
                  @click="saveAndAccept"
                >
                  <v-icon
                    left
                    color="red"
                  >
                    mdi-content-save-alert-outline
                  </v-icon>{{ $t('forms.buttons.validate_without_photo_processing') }}
                </v-btn>
                <v-btn
                  v-else
                  :disabled="!curation.enabled"
                  color="green darken-3"
                  class="white--text"
                  @click="saveAndAccept"
                >
                  <v-icon left>
                    mdi-content-save-move-outline
                  </v-icon>{{ $t('forms.buttons.validate') }}
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </v-dialog>
    <v-dialog
      v-model="rejection.show"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar
            color="primary"
            dark
          >
            {{ $t('views.curation.forms.labels.reject') }}
          </v-toolbar>
          <v-card-text>
            <v-radio-group
              v-model="rejection.reason_type"
              row
            >
              <v-radio
                v-for="reason in rejection.reasons"
                :key="reason.value"
                class="py-1 my-2"
                :label="reason.label"
                :value="reason.value"
                @click="getRejectionReasonPlaceholder(reason.value)"
              />
            </v-radio-group>
            <div class="text-h6 py-2">
              {{ $t('views.curation.forms.labels.rejection_reason') }}
            </div>
            <v-textarea
              v-model="rejection.reason"
              rows="2"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="grey lighten-1"
              class="white--text"
              @click="dialog.value = false"
            >
              <v-icon left>
                mdi-cancel
              </v-icon>{{ $t('forms.buttons.cancel') }}
            </v-btn>
            <v-spacer />
            <v-btn
              color="red accent-4"
              class="white--text"
              @click="dialog.value = false; reject()"
            >
              <v-icon left>
                mdi-minus-circle
              </v-icon>{{ $t('forms.buttons.reject') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-row>
</template>

<script>
import EventBus from '@/plugins/event-bus'
import inputMixin from '@/mixins/input'
import LinkProduct from '@/components/base/LinkExternal/Product.vue'
import { mapGetters } from 'vuex'
import ProductContentForm from '@/components/base/Product/ContentForm'
import ProductFeaturesForm from '@/components/base/Product/FeaturesForm'
import productFormMixin from '@/mixins/productForm'
import ProductModerationForm from '@/components/base/Product/ModerationForm'
import ProductPhotoForm from '@/components/base/Product/PhotoForm'
import ProductVariantsForm from '@/components/base/Product/VariantsForm'
import RejectionAlert from '@/components/core/RejectionAlert'
import snackbarMixin from '@/mixins/snackbar'
import StateLabel from '@/components/core/StateLabel'

export default {
  name: 'CurationEdit',
  components: {
    LinkProduct,
    ProductModerationForm,
    ProductFeaturesForm,
    ProductPhotoForm,
    ProductVariantsForm,
    ProductContentForm,
    RejectionAlert,
    StateLabel,
  },
  mixins: [snackbarMixin, inputMixin, productFormMixin],
  props: {
    fetchNextProductId: {
      type: Function,
      default: () => null,
    },
  },
  data () {
    return {
      productId: null,
      show: false,
      loading: false,
      loadingText: this.$i18n.t('views.products.forms.messages.load.progress'),
      rejection: {
        show: false,
        reason: null,
        reason_type: null,
        reasons: [
          { label: this.$i18n.t('views.curation.enum.rejection_reason.picture'), value: 'picture' },
          { label: this.$i18n.t('views.curation.enum.rejection_reason.price'), value: 'price' },
          { label: this.$i18n.t('views.curation.enum.rejection_reason.style'), value: 'style' },
          {
            label: this.$i18n.t('views.curation.enum.rejection_reason.synthetic_material'),
            value: 'synthetic_material',
          },
          { label: this.$i18n.t('views.curation.enum.rejection_reason.condition'), value: 'condition' },
          { label: this.$i18n.t('views.curation.enum.rejection_reason.too_small'), value: 'too_small' },
          { label: this.$i18n.t('views.curation.enum.rejection_reason.too_recent'), value: 'too_recent' },
          { label: this.$i18n.t('views.curation.enum.rejection_reason.other'), value: 'other' },
        ],
      },
      curation: {
        warning: false,
        enabled: false,
        byPassPhotoProcessing: false,
        next: true,
      },
      product: {},
      errors: {
        translations: [],
        variants: [],
      },
      form: {},
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'isInternalModerator', 'isExternalModerator']),
  },
  watch: {
    product () {
      this.form = {
        categoryId: this.product?.category?.id,
        family: this.product?.family,
        attributes: this.product.attributes,
        dropIds: this.product.drop_ids,
        tagIds: this.product.tags.map(t => t.id),
        target: this.product.target,
        weight: this.product?.weight,
        title: this.product?.title,
        description: this.product?.description,
        defects: this.product?.defects,
        sizing: this.product?.sizing,
        translations: this.product?.translations ?? [],
        score: this.product?.score,
        favorite: this.product?.favorite,
        wornPhotos: this.product?.worn_photos,
        inImparfaiteWarehouse: this.product?.in_imparfaite_warehouse,
        photos: this.product?.photos?.map((photo) => ({
          id: photo.photo.id,
          isDeleted: photo.deleted,
          photo: {
            src: photo.photo.src,
            kind: photo.photo.kind,
            state: photo.photo.state,
          },
        })),
        variants: this.product?.variants?.map((variant) => ({
          id: variant.id,
          sku: variant.sku,
          sizes: variant.size_attributes,
          externalReference: variant.external_reference,
          eanCode: variant.ean_code,
          stock: variant.stock,
          basePrice: variant.base_price,
          price: variant.price,
          discountRate: variant.discount_rate,
          measures: variant.measures.map((measure) => ({
            value: measure.value ? parseFloat(measure.value) : null,
            slug: measure.kind,
          })),
          inventory: variant.inventory,
        })),
      }

      this.errors = {
        categoryId: null,
        family: null,
        dropIds: null,
        tagIds: null,
        weight: null,
        title: null,
        description: null,
        translations: this.product.translations.map(() => ({
          title: null,
          description: null,
          defect: null,
          sizing: null,
        })),
        defects: null,
        sizing: null,
        photos: null,
        variants: this.product?.variants?.map((variant) => ({
          size: null,
          measures: Array(variant.measures.length).fill({ value: null }),
        })),
      }

      if ((this.isInternalModerator || this.isAdmin) && this.product?.state === 'in_internal_review') {
        this.curation.enabled = true
      }
    },
  },
  created () {
    EventBus.$on('EDIT_PRODUCT', async ({ productId, index }) => {
      this.index = index
      await this.start(productId)
    })
  },
  methods: {
    async start (id) {
      this.show = true
      this.curation.warning = false
      this.loadingText = this.$i18n.t('views.curation.forms.messages.load.progress')
      this.loading = true

      try {
        await this.$axios.post('v3/products/' + id + '/reviews/starts', {})
        this.curation.warning = false
      } catch (error) {
        this.curation.warning = true
      } finally {
        this.loading = false
        await this.load(id)
      }
    },
    async load (id) {
      this.loadingText = this.$i18n.t('views.curation.forms.messages.load.progress')
      this.loading = true

      try {
        const response = await this.$axios.get('v3/products/' + id)
        this.product = response.data
      } catch (error) {
        const error_message = error?.response?.data['hydra:description'] ??
          error?.response?.data['detail'] ?? 'No context'
        this.snackbarError(this.$i18n.t('views.curation.forms.messages.load.failed', { error: error_message }))
      } finally {
        this.loading = false
      }
    },
    async saveAndAccept () {
      if (!await this.save()) {
        return
      }

      this.loadingText = this.$i18n.t('views.curation.forms.messages.validate.progress')
      this.loading = true

      try {
        await this.$axios.post('v3/products/' + this.product.id + '/reviews/accepts', {
          skip_processing: this.curation.byPassPhotoProcessing,
        })
        this.curation.warning = false
        this.snackbarSuccess(
          this.$i18n.t('views.curation.forms.messages.validate.succeed', { productTitle: this.product.title })
        )
        if (this.curation.next === true) {
          await this.getNext()
        } else {
          await this.load(this.product.id)
        }
      } catch (error) {
        let error_message = error?.response?.data['hydra:description'] ??
          error?.response?.data['detail'] ?? 'No context'
        this.snackbarError(this.$i18n.t('views.curation.forms.messages.validate.failed', { error: error_message }))
      } finally {
        this.loading = false
      }
    },
    async save () {
      this.loadingText = this.$i18n.t('views.products.forms.messages.save.progress')
      this.loading = true

      const product = {
        title: this.form.title,
        description: this.form.description,
        target: this.form.target,
        photos: this.form.photos.map((photo) => {
          return {
            id: photo.id,
            kind: photo.photo.kind,
          }
        }),
        translations: this.form.translations.map(v => ({
          language: v.language,
          title: v.title,
          description: v.description,
          defect: v.defect,
          sizing: v.sizing,
        })),
        category: this.form.categoryId,
        defects: this.form.defects,
        sizing: this.form.sizing,
        variants: this.form.variants.map(v => ({
          id: v.id,
          sizes: v.sizes.map((attribute) => ({
            name: attribute.name,
            kind: attribute.kind,
            taxonomy_id: attribute.taxonomy_id,
          })),
          external_reference: v.externalReference,
          ean_code: v.eanCode,
          stock: v.stock,
          base_price: v.basePrice,
          price: v.price,
          discount_rate: v.discountRate,
          measures: v.measures.map(m => ({
            kind: m.slug,
            value: m.value,
          })),
        })),
        family: this.form.family,
        attributes: this.form.attributes.map((attribute) => ({
          name: attribute.name,
          kind: attribute.kind,
          taxonomy_id: attribute.taxonomy_id,
        })),
        drops: this.form.dropIds,
        tags: this.form.tagIds,
        weight: this.form.weight,
        score: this.form.score,
        favorite: this.form.favorite,
        wornPhotos: this.form.wornPhotos,
        inImparfaiteWarehouse: this.form.inImparfaiteWarehouse,
      }

      try {
        const response = await this.$axios.put('v3/products/' + this.product.id, product)
        this.product = response.data

        this.snackbarSuccess(
          this.$i18n.t('views.curation.forms.messages.save.succeed', { productTitle: this.product.title })
        )

        return true
      } catch (error) {
        if (error?.response?.data['@type'] === 'hydra:Error') {
          this.snackbarError(
            this.$t('views.curation.forms.messages.save.failed', {
              error: error?.response?.data['hydra:description'] ?? 'internal',
            })
          )

          return false
        }
        if (error?.response?.data['@type'] !== 'ConstraintViolationList') {
          this.snackbarError(this.$t('views.curation.forms.messages.save.failed', { error: 'internal' }))

          return false
        }

        this.apiViolationToErrors(error?.response?.data?.violations ?? [])
      } finally {
        this.loading = false
      }
    },
    async reject () {
      this.loadingText = this.$i18n.t('views.curation.forms.messages.reject.progress')
      this.loading = true

      const rejection = {
        reason: this.rejection.reason,
        type_reason: this.rejection.reason_type,
      }

      try {
        await this.$axios.post('v3/products/' + this.product.id + '/reviews/rejects', rejection)
        this.curation.warning = false
        this.snackbarSuccess(
          this.$i18n.t('views.curation.forms.messages.reject.succeed', { productTitle: this.product.title })
        )
        if (this.curation.next === true) {
          await this.getNext()
        } else {
          await this.load(this.product.id)
        }
      } catch (error) {
        const error_message = error?.response?.data['hydra:description'] ??
          error?.response?.data['detail'] ?? 'No context'
        this.snackbarError(this.$i18n.t('views.curation.forms.messages.reject.failed', { error: error_message }))
      } finally {
        this.loading = false
      }
    },
    async cancel () {
      this.loadingText = this.$i18n.t('views.curation.forms.messages.cancel.progress')
      this.loading = true

      try {
        await this.$axios.post('v3/products/' + this.product.id + '/reviews/cancels', {})
        this.curation.warning = false
        this.snackbarSuccess(
          this.$i18n.t('views.curation.forms.messages.cancel.succeed', { productTitle: this.product.title })
        )
        this.close()
      } catch (error) {
        const error_message = error?.response?.data['hydra:description'] ??
          error?.response?.data['detail'] ?? 'No context'
        this.snackbarError(this.$i18n.t('views.curation.forms.messages.cancel.failed', { error: error_message }))
      } finally {
        this.loading = false
      }
    },
    showRejectForm () {
      this.rejection.show = true
    },
    async getNext () {
      this.loadingText = this.$i18n.t('views.curation.forms.messages.getting_next.progress')
      this.loading = true

      try {
        const productId = await this.fetchNextProductId()
        if (null === productId) {
          this.snackbarWarning(
            this.$i18n.t('views.curation.forms.messages.getting_next.failed', {
              error: this.$i18n.t('views.curation.forms.messages.getting_next.empty'),
            })
          )
          this.close()

          return
        }
        await this.start(productId)
      } catch (error) {
        this.snackbarError(this.$i18n.t('views.curation.forms.messages.getting_next.failed', { error }))
      } finally {
        this.loading = false
      }
    },
    getRejectionReasonPlaceholder (reason_type) {
      this.rejection.reason = this.$i18n.t('views.curation.enum.rejection_reason_placeholder.' + reason_type)
    },
    close () {
      EventBus.$emit('CURATION_REFRESH_LIST', {})
      this.clear()
      this.loadingText = this.$i18n.t('views.curation.forms.messages.load.progress')
      this.loading = false
      this.show = false
      this.rejection.show = false
      this.curation.warning = false
    },
    clear () {
      this.product = {}
      this.rejection.show = false
      this.rejection.reason = null
      this.rejection.reason_type = null
      this.curation.warning = false
    },
  },
}
</script>

<style scoped>
.v-toolbar.product-state {
  background-color: #EF9A9A;
}

.v-toolbar.product-state.by-pass {
  border-color: #ff4136;
}

.v-toolbar.product-state-in_internal_review,
.v-toolbar.product-state-in_external_review {
  background-color: #C5E1A5;
}
</style>
